window.onload = function () {
    var media = document.getElementById("myOverlayVideo");
    var btn = document.getElementById("playOnOverlayPage");
    btn.addEventListener('click', () => {
        if (media.paused) {
            media.play();
            btn.innerHTML = 'PAUSE'
        } else {
            media.pause();
            btn.innerHTML = 'PLAY'
        }
    })
    var swipeclose = document.getElementById('logo-container')
    swipeclose.onclick = function () {
        setTimeout(function () {
            var social = document.getElementsByClassName('social-connections');
            social[0].classList.remove('horizTranslate');
        }, 500);
        setTimeout(function () {
            var social = document.getElementsByClassName('leader-data');
            social[0].classList.remove('horizontalAnimate');
        }, 500);
        document.getElementById('video-box').classList.add('do-close');
        document.getElementById('blue-box').style = 'margin-left: -30%'
        document.body.setAttribute('style', 'overflow-x: hidden;');
        media.pause();
        btn.innerHTML = 'PLAY'
        document.getElementById('header-container').style = 'background-color: transparent;';
        var navBtns = document.getElementsByClassName('nav-options')
        for (var i = 0; i < navBtns.length; i++) {
            var anchor = navBtns[i];
            anchor.style = 'color: white;';
        }
        setTimeout(function () {
            document.getElementById('video-box').classList.remove('do-open');
        }, 1000)
    }
}